body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* //carousal section */

.construction-section {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.construction-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.construction-section .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.construction-section .section-text {
  flex-basis: 50%;
  padding: 20px;
  
}


.construction-section h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.construction-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.construction-section .buttons {
  display: flex;
  margin-top: 20px;
 /* margin-left: 60px; */
}


.construction-section{
  background-image: url(./assets/img1.webp);
  height: 500px;
}
.contbtn{
  background-color: #FFC107;
  color: #000;
  font-weight: 600;
  margin-right: 30px;
}
.contbtn:hover{
  background-color: #000;
  color: #FFC107;
  font-weight: 600;
}
.serbtn, .subbtn{
  background-color: #000;
  color: #FFC107;
  font-weight: 600;
}
.serbtn:hover{
  background-color: #FFC107;
  color: #000;
  font-weight: 600;
}
.subbtn:hover{
  background-color: #FFC107;
  color: #000;
  font-weight: 600;
  border: 1px solid #000;
  border-radius: 5px;
}
/* end here carousal */

.imgbox{
  /* width: 100px;
  height: 450px; */
  box-shadow: 23px 23px 0px -9px #FFC107;
}
.imgboxx{
  /* box-shadow: 23px 23px 0px -9px #FFC107;  */
  box-shadow: 0px 25px 0px -11px #FFC107, -25px 0 0px -11px #FFC107;
}
.imgboxxx{
  box-shadow: 0px 25px 0px -11px #FFC107, 25px 0 0px -11px #FFC107;
}

.texture{
  background-image: url(./assets/texture.webp);
}


.about{
  background-image: url(./assets/about.webp);
  /* width: 100%; */
  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Additional style for hover effect (optional) */
.container #product:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
/**/

.social:hover{
color: #FFC107;
}


/* media query  for heading */
@media (max-width: 767px) {
  #block{
    padding: 40px;
  }
}

/* media query  for paragraph */
@media (max-width: 767px) {
  #para{
    padding: 40px;
  }
}

#abouthead h1{
  font-size: 70px;
}
#abouthead p{
  font-size: 25px;
}


/* media query fo about heading */
@media(max-width: 744px) {
  #abouthead {
margin-top: -40px;

  }
}
@media(max-width: 600px) {
  #abouthead {
margin-top: -80px;

  }
}
@media(max-width: 526px) {
  #abouthead {
margin-top: -120px;

  }
}
@media(max-width: 456px) {
  #abouthead {
margin-top: -150px;

  }
  #abouthead h1{
    font-size: 40px;
   
    
  }
}
@media(max-width: 370px) {
  #abouthead {
margin-top: -50px;

  }
  #abouthead h1{
    font-size: 30px;
   
  }
  #abouthead p{
    font-size: 20px;
  }
}