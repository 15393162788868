/*header section*/

.header_section .header_top .header_top_container {
    display: flex;
  }
  
  .nav-link{
    font-weight: bold;
  }
  
  .header_section .header_top .contact_nav p{
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .header_section .header_top .contact_nav p i {
    margin-right: 5px;
    background-color: #FFC107;
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #ffff;
    border-radius: 100%;
  }
  
  .header_section .header_top .contact_nav p:hover i {
    background-color: #000;
  }
  
  .header_section .header_top .social_box {
    display: flex;
     justify-content: flex-end;
  }
  
  .header_section .header_top .social_box a i {
    margin-right: 5px;
    background-color: #FFC107;
    width: 28px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #ffffff;
    border-radius: 100%;
  }
  
  .header_section .header_top .social_box a:hover i {
    background-color: #000;
  }
  
  .header_section .header_bottom {
    padding: 10px 0 ;
  }
  
  .header_section .header_bottom .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
  
  .navbar-brand {
    color: #000;
    font-weight: bold;
    font-size: 24px;
  }
  
  .navbar-brand span {
    color: #FFC107;
  }
  
  .navbar-brand:hover {
    color: inherit;
  }
  
  .custom_nav-container {
    padding: 0;
  }
  
  .custom_nav-container .navbar-nav {
    margin-left: auto;
  }
  
  .custom_nav-container .navbar-nav .nav-item .nav-link {
    padding: 5px 30px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-nav .nav-item .nav-link i {
    margin-right: 5px;
  }
  
  .custom_nav-container .navbar-nav .nav-item:hover .nav-link {
    color: #FFC107;
  }
  
  .custom_nav-container .nav_search-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    border: none;
    color: #ffffff;
  }
  
  .custom_nav-container .nav_search-btn:hover {
    color: #FFC107;
  }
  
  .custom_nav-container .navbar-toggler {
    outline: none;
  }
  
  .custom_nav-container .navbar-toggler {
    padding: 0;
    width: 37px;
    height: 42px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler span {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #000;
    margin: 7px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler span::before, .custom_nav-container .navbar-toggler span::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f8e809;
    top: -10px;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  
  .custom_nav-container .navbar-toggler span::after {
    top: 10px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] span {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] span::before, .custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    top: 0;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: 0;
    margin-bottom: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
    display: none;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin: 0;
    margin-top: -4px;
  }
  
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
  .custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
    -webkit-transform: none;
            transform: none;
  }
  
  /*end header section*/
  